import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AboutUsItem from '../common/aboutUsItem';
import Info, { ButtonWrapper } from '../common/info';
import {
  CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, media, TABLET,
} from '../../constants/screens';
import {
  BLACK, BLUE, DARK_GRAY, WHITE,
} from '../../constants/colors';

const Introduction = ({
  image: { alt, file: { localFile: { childImageSharp: { sizes } } } },
  title, subTitle, description, button: { text, url },
}) => (
  <Container>
    <Content>
      <AboutUsItem
        reversed
        image={sizes}
        maxImageWidth="380px"
        imgAltAttr={alt}
        css={aboutUsComponentCustomStyles}
      >
        <Info
          withButton
          title={title}
          subTitle={subTitle}
          text={description}
          titleColor={DARK_GRAY}
          subTitleColor={BLUE}
          textColor={BLACK}
          subTitleFontSize="40px"
          buttonText={text}
          buttonBackground={BLUE}
          buttonHoverTextColor={BLUE}
          link={url}
          buttonColor={WHITE}
          pageName="custom-research"
          css={infoComponentCustomStyles}
        />
      </AboutUsItem>
    </Content>
  </Container>
);

Introduction.propTypes = {
  image: PropTypes.shape(),
  title: PropTypes.string,
  description: PropTypes.string,
  subTitle: PropTypes.string,
  button: PropTypes.shape(),
};

Introduction.defaultProps = {
  image: { file: { localFile: { childImageSharp: { sizes: {} } } } },
  title: '',
  description: '',
  subTitle: '',
  button: {
    url: '',
    text: '',
  },
};

const infoComponentCustomStyles = `
  & > *:nth-child(1) {
    margin-bottom: 4px;
  }
  & > *:nth-child(3) {
    line-height: 1.68;
  }
  & > div:nth-child(2) > * {;
      margin-bottom: 10px;
  }
  & > *:last-child {
    margin-top: 32px;
  }
  ${media.tablet`
    {
      & > *:nth-child(1) {
        margin-bottom: 0;
        font-size: 14px;
      }
      & > div:nth-child(2) {
        margin: 0 60px 11px 60px;
        font-size: 24px;
        & > * {
          margin-bottom: 0;
        }
      }
      & > *:nth-child(3) {
        line-height: 1.68;
      }
    }
  `}
`;

const aboutUsComponentCustomStyles = `
  & > div:last-child {
    margin: auto;
    align-self: flex-end;
    margin-bottom: 0;
  }

  @media screen and (max-width: ${TABLET}) {
    flex-direction: column-reverse;
    & > div:last-child {
      align-self: center;
      width: 80%;
      margin-bottom: 32px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 5% 120px 5%;

  ${media.tablet`
    padding: 150px 5% 40px 5%;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: center;
  
  > div {
    width: 100%;
  }
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  ${media.tablet`
    flex-direction: column;
    align-items: center;
  `}
  
  & ${ButtonWrapper} {
    margin-top: 20px;
    
    ${media.tablet`
       padding: 0;
       margin-top: 0px;
    `}
    
    ${media.mobile`
       padding: 0;
       margin-top: 0px;
    `}
  }
`;

export default Introduction;
