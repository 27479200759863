import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AboutUsItem from '../common/aboutUsItem';
import {
  CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, TABLET, media,
} from '../../constants/screens';
import {
  WHITE,
  TURQUOISE,
  BLUE,
  BLACK,
} from '../../constants/colors';
import Info from '../common/info';

const Sections = ({ sections, light }) => (
  <SectionsContainer>
    <MainContent>
      {
        sections.map(({
          icon,
          id,
          title,
          lead,
          description: { html },
        }, index) => (
          <AboutUsItem
            key={id}
            image={icon.file.localFile.childImageSharp.sizes}
            imgAltAttr={icon.alt}
            reversed={!!(index % 2)}
            maxImageWidth="190px"
            css={customAboutItemStyle}
          >
            <Info
              subTitleColor={light ? BLUE : WHITE}
              titleColor={TURQUOISE}
              textColor={light ? BLACK : WHITE}
              title={title}
              subTitle={lead}
              text={html}
              reversed={!!(index % 2)}
              css={infoComponentCustomStyles}
            />
          </AboutUsItem>
        ))
      }
    </MainContent>
  </SectionsContainer>
);


const customAboutItemStyle = `
  {
    & > div:first-child {
      flex: 0.5;
      & > div > *:nth-child(2) > * {
        margin-bottom: 10px;
      }
    }
    & > div:last-child {
      margin: 0 auto;
      padding-right: 50px;
    }

    @media screen and (max-width: ${TABLET}) {
      & > div:last-child {
        margin: 0 auto;
        padding-right: 0;
        max-width: 40%;
      }
    }
  }
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 120px 0 120px 0;
  width: 100%;
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 0 75px 0;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${CONTAINER_WIDTH};
  width: 90%;
  align-items: center;

  > div:not(:last-child) {
    margin-bottom: 100px;
  }

  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
    
    & > div:not(:last-child) {
      margin-bottom: 70px;
    }
  `};
`;

const infoComponentCustomStyles = `
  & > *:nth-child(3) {
    line-height: 1.68;
  }
  ${media.tablet`
    {
      & > *:nth-child(3) {
        line-height: 1.68;
      }
    }
  `}
`;

Sections.propTypes = {
  sections: PropTypes.instanceOf(Array).isRequired,
  light: PropTypes.bool,
};

Sections.defaultProps = {
  light: false,
};

export default Sections;
