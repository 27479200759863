import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TABLET } from '../../constants/screens';


const ImageWrapper = ({
  reversed,
  image,
  color,
  children,
  ...rest
}) => (
  <StyledContainer css={`background-color: ${color};`} {...rest}>
    {image && <BackgroundImage background={image.src} />}
    {children}
  </StyledContainer>
);

ImageWrapper.propTypes = {
  description: PropTypes.string,
  color: PropTypes.string,
  image: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
  reversed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

ImageWrapper.defaultProps = {
  description: '',
  image: {},
  fullWidth: false,
  reversed: true,
  children: null,
  color: '',
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const BackgroundImage = styled.div`
  position: absolute;
  background-image: none;
  pointer-events: none;

  @media(min-width: ${TABLET}) {
    top: 90px;
    left: 80px;
    right: 80px;
    bottom: 90px;
    background: url(${props => props.background}) no-repeat;
    background-size: contain;
    background-position: center;
  }
`;

export default ImageWrapper;
