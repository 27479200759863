import styled from 'styled-components';
import RoundedButton from './roundedButton';
import { WHITE } from '../../constants/colors';

const GradientButton = styled(RoundedButton)`
  background-image: linear-gradient(70deg, #01fcf3 0%,#6b27e8 66%,#4a1eb2 100%);
  max-width: 350px !important;
  border: none !important;
  background-size: 150%;
  background-position: 25%;
  margin: 0;
  box-shadow: -7px 15px 30px 0 rgba(191, 208, 225, 0.75);
  
  &:hover {
    background-position: 50%;
  }
`;

GradientButton.defaultProps = {
  color: WHITE,
};

export default GradientButton;
