import React from 'react';
import PropTypes from 'prop-types';
import { compose, path } from 'ramda';
import { graphql } from 'gatsby';
import Introduction from '../components/customResearchPage/introduction';
import Sections from '../components/customResearchPage/sections';
import ImageWrapper from '../components/customResearchPage/imageWrapper';
import GetInTouch from '../components/customResearchPage/getInTouch';
import withStickyHeader from '../enhancers/withStickyHeader';
import withCustomLayout from '../enhancers/withCustomLayout';
import { DARK_SCHEME, LIGHT_GRAY_1, DARK_BLUE } from '../constants/colors';

const CustomResearch = (props) => {
  const {
    data: {
      page: { introduction, sections },
    },
  } = props;
  const sectionImageBg = sections[0].image.file.localFile.childImageSharp.sizes;
  const sectionOne = path([0, 'content'], sections);
  const sectionTwo = path([1, 'content'], sections);
  const sectionThree = path([2, 'content'], sections);
  const contactsSection = sections.find(({ meta = '' }) => meta.includes('contact'));

  return (
    <>
      <Introduction {...introduction} />
      {
        sectionOne && (
          <ImageWrapper image={sectionImageBg} color={DARK_BLUE}>
            <Sections sections={sectionOne} />
          </ImageWrapper>
        )
      }
      {sectionTwo && <Sections sections={sectionTwo} light />}
      {
        sectionThree && (
          <ImageWrapper color={LIGHT_GRAY_1}>
            <Sections sections={sectionThree} light />
          </ImageWrapper>
        )
      }
      {
        contactsSection && (
          <ImageWrapper image={sectionImageBg}>
            <GetInTouch section={contactsSection} />
          </ImageWrapper>
        )
      }
    </>
  );
};

CustomResearch.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape().isRequired,
  }).isRequired,
};

export default compose(
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
)(CustomResearch);

// eslint-disable-next-line
export const query = graphql`
  query CustomResearchPageQuery {
    page(url: { regex: "/custom-research/" }) {
      introduction {
        id: _id
        title
        description
        subTitle
        button {
          url
          text
        }
        image {
          alt
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 320) { 
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
      sections {
        id: _id
        name
        meta
        title
        description
        subTitle
        button {
          url
          text
        }
        title
        image {
          alt
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 640) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
        content {
          id: _id
          icon {
            alt
            file {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 640) {
                    ...GatsbyImageSharpSizes_noBase64
                  }
                }
                publicURL
              }
            }
          }
          title
          lead
          button {
            text
            url
          }
          description {
            html
          }
        }
      }
    }
  }
`;
