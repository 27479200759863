import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyLink from 'gatsby-link';
import GradientButton from '../buttons/gradientButton';
import { InfoTitle } from '../common/info';
import {
  DARK_GRAY, WHITE, BLUE, BLACK,
} from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const GetInTouch = ({
  section: {
    description, title, subTitle, button: {
      url, text,
    },
  },
}) => (
  <GetInTouchContainer>
    <InfoTitle titleColor={DARK_GRAY} css={customInfoTitleStyles}>
      {title}
    </InfoTitle>
    <InfoSubTitle
      subTitleFontSize="33px"
      subTitleColor={BLUE}
    >
      {subTitle}
    </InfoSubTitle>
    {description && (
      <InfoText textColor={BLACK}>{description}</InfoText>
    )}
    <GradientButton
      text={text}
      color={WHITE}
      background={BLUE}
      hoverTextColor={WHITE}
      tag={GatsbyLink}
      to={url}
    />
  </GetInTouchContainer>
);

GetInTouch.propTypes = {
  section: PropTypes.shape().isRequired,
};

const customInfoTitleStyles = `
  {
    margin-bottom: 4px;
  }
`;

const GetInTouchContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  padding: 150px 5%;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    padding: 120px 5%;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

const InfoSubTitle = styled.h3`
  display: block;
  font-size: ${props => props.subTitleFontSize};
  text-transform: uppercase;
  color: ${props => props.subTitleColor};
  margin-bottom: 10px;
  letter-spacing: normal;
  line-height: normal;

  @media screen and (max-width: ${LAPTOP}) {
    font-size: 26px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: ${TABLET}) {

    text-align: center;
  }

  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const InfoText = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  font-weight: 400;
  line-height: 1.4;
  color: ${props => props.textColor};
  max-width: 550px;
  width: 100%;
  margin-bottom: 60px;

  @media screen and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 60px;
    text-align: center;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    margin-bottom: 30px;
    font-size: 16px;
  }
`;

export default GetInTouch;
